.header-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #b4436c;

  width: 100%;
  height: 44px;
}
.header-main :hover .head-button {
  color: #fff;
  background-color: #a0138e;
  cursor: pointer;
}
.header-heading {
  font-size: 18px;
  font-family: "palatino linotype", serif;
  color: #fff;
  line-height: 24px;
  flex-shrink: 0;
}
.head-line-one {
  display: flex;
  flex-direction: row;
}
.header-logo {
  width: 36px;
  height: 16px;
}
.head-line-three {
  margin-left: 5px;
}
.head-button {
  font-family: wixFreemiumFontW01-65Medi, wixFreemiumFontW02-65Medi,
    wixFreemiumFontW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ,
    meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #a0138e;
  border: 1px solid #a0138e;
  display: inline-flex;
  width: 112px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
  flex-shrink: 0;
  text-align: center;
  margin-left: 5px;
}
.hr-line {
  width: 100%;
  color: #a0138e;

  background-color: #a0138e;
  height: 3px;
  margin: 0;
}
@media screen and (max-width: 786px) {
  .header-main {
    width: 100%;
    height: auto;
    align-items: center;
  }
  .header-heading {
    text-align: center;
    font-size: 18px;

    width: 100%;
    height: auto;
    margin-top: 10px;
    padding: 10px;
  }
}
